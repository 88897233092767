/**
 * App main javascript
 *
 * here we can define our needs
 */
import Rollbar from 'rollbar'

/**
 * Enable rollbar
 */
if (ENVIRONMENT !== 'development') {
  const CONFIG_ROLLBAR = {
    accessToken: ROLLBAR,
    captureUncaught: true,
    captureUnhandledRejections: true,
    enabled: true,
    environment: ENVIRONMENT,
    payload: {
      client: {
        javascript: {
          code_version: VERSION,
          source_map_enabled: true,
          guess_uncaught_frames: true
        }
      }
    }
  }

  window.Rollbar = new Rollbar(CONFIG_ROLLBAR)
}
